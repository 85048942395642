<template>
    <setting-layout title="Verify Account by Yoti">
        <v-container class="mb-1">
            <v-flex text-center :class="{
                'error--text': error,
                'green--text': success,
            }">
                <h1 v-if="title">{{ title }}</h1>
                <p v-if="success">{{ success }}</p>
                <p v-if="error">{{ error }}</p>
            </v-flex>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout"
import {mapActions} from "vuex"

export default {
    name: "Verified",
    components: {
        SettingLayout,
    },
    data() {
        return {
            title: false,
            success: false,
            error: false,
        }
    },
    methods: {
        ...mapActions([
            'sendYotiToken',
        ]),

        prepareYotiToken() {
            this.sendYotiToken({
                token: this.$route.query.token
            }).then((response) => {
                this.title = response.title
                this.success = response.message
            }).catch((error) => {
                this.title = error.response.data.title
                this.error = error.response.data.message || error.message
            })
        },
    },
    mounted() {
        if (!this.$route.query.token) {
            this.$router.push({'name': 'user.setting.creator'})
        }
        this.prepareYotiToken()
        setTimeout((() => this.$window.location = '/user/setting/creator'), 3000)
    }
}
</script>
